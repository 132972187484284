import React, { useState } from "react";
import { useModal } from "../store/ModalState";

export default function InstructionModal({ title }) {
  const showModal = useModal((state) => state.showModal);
  const setShowModal = useModal((state) => state.setShowModal);
  
   return (
    <>
      {showModal ? (
        <>
          <div className="print:text-sm backdrop-blur-xl bg-white/30  justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-1 xl:my-6 mx-auto xl:w-2/5 w-full">
              {/*content*/}
              <div className="px-1 xl:px-20 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}

                {/* <div className="items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <div className="flex">
                    <div className="flex-none w-16 pt-2">
                      <img
                        src="/logo.png"
                        alt="Police Logo"
                        className="h-16 w-14"
                      />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-2xl font-semibold text-left mt-3">
                        Nigeria Police Force
                      </h3>
                      <h3 className="text-xl font-semibold text-left">
                        Central Motor Registry
                      </h3>
                    </div>
                  </div>
                </div> */}
                    <p className="text-3xl font-bold text-red-500 text-center pt-5 pb-4">Notice…!!!</p>
               <div className="text-lg">
                    <div className="mb-4 text-justify">
                        Please, ensure that you enter the accurate NIN or TIN/CAC/JTB Number. CAC numbers should begin with <span className="font-bold">RC or BN or IT </span> as applicable, e.g. <span className="font-bold">RC1234567.</span>
                    </div>

                    <div className="mb-4 text-justify">
                        Enter name exactly as they appear in NIN/TIN/CAC/JTB details. Do not include titles such as <span className="font-bold">DR., PROF., REV., CHIEF, ENGR., ALH.</span>, etc.
                    </div>

                    <div className="mb-4 text-justify">
                    The above applies when entering details of an organization’s Representative.
                    </div>

                    <div className="mb-4 text-justify">
                        Ensure that the accurate Vehicle Licence Plate and Chassis Numbers are entered.
                    </div>

                    <div className="mb-4 text-justify">
                    <span className="font-bold font-underlined">Sale/Purchase of Vehicle:</span> <br />
                    If the vehicle is purchased from a previous owner/user, inquire from the seller if the vehicle has a CMR certificate.
                        <p className="ml-4">i.  If <span className="font-bold">YES</span>, create your profile and thereafter request the seller to <span className="font-bold">initiate a change of ownership</span> from his/her own profile for the change of ownership on the CMR portal.</p>
                        <p className="ml-4">ii. If <span className="font-bold">NO</span>, create your profile and follow the prompts to apply for the CMR certificate.</p>
                    </div>
               </div>
                          
                {/*footer*/}
                <div className="flex mb-4">
                    <div className="flex-1 w-1/3"></div>
                    <div className="flex-1 w-1/3 flex justify-center items-center space-x-2">
                        <button
                        className={`${"bg-blue-500 cursor-pointer"
                        }  rounded text-center w-32 text-gray-100 px-3 py-1 border border-gray-200`}
                        type="button"
                        onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                    <div className="flex-1 w-1/3"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
